<template>
    <div class="treadDetail-content">
        <el-breadcrumb separator="/" class="student-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/student/operationpromotion/index' }">搜索引擎优化</el-breadcrumb-item>
            <el-breadcrumb-item v-if="tabName" :to="{ path: '/student/operationpromotion/industrymarket' }">数据分析</el-breadcrumb-item>
            <el-breadcrumb-item v-if="tabName" :to="{ path: '/student/operationpromotion/commoditylist' }">商品榜单</el-breadcrumb-item>
            <el-breadcrumb-item v-if="tabName">{{tabName}}</el-breadcrumb-item>
            <el-breadcrumb-item>商品详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="page-title">店铺宝贝</div>
        <div class="goods-detail">
            <div class="cover">
                <img v-if="goodDetail" :src="goodDetail.image ? goodDetail.image : require('../../../../assets/images/noimg.png')" alt="">
            </div>
            <div class="goods-title" v-if="goodDetail">{{goodDetail.name}}</div>
        </div>
        <div class="goods-attr">
            <div class="attr-title">商品属性</div>
            <div class="attr-list" v-if="goodDetail">
                <div class="item" :title="attrItem.name + '：' + attrItem.data" v-for="(attrItem, attrIndex) in goodDetail.goods_info" :key="`attrList_${attrIndex}`">
                    {{attrItem.name}}: {{attrItem.data}}
                </div>
            </div>
        </div>
        <div class="goods-overview">
            <div class="title">商品概况</div>
            <div class="overview-content">
                <div class="oo-item">
                    <div class="oo-title">
                        <img src="../../../../assets/images/good-detail-yinliu.png" alt="">
                        <p>引流漏斗</p>
                    </div>
                    <div class="oo-right">
                        <div class="item">
                            <p class="text">展现量</p>
                            <p class="num" v-if="goodDetail">{{goodDetail.all_searcher}}</p>
                        </div>
                        <div class="item">
                            <p class="text">点击率</p>
                            <!-- <p class="num" v-if="goodDetail">{{goodDetail.all_conversion_num}}</p> -->
                            <p class="num" v-if="goodDetail">27%</p>
                        </div>
                        <div class="item">
                            <p class="text">点击量</p>
                            <p class="num" v-if="goodDetail">{{goodDetail.all_click_num}}</p>
                        </div>
                    </div>
                </div>
                <div class="oo-item">
                    <div class="oo-title">
                        <img src="../../../../assets/images/good-detail-zhuanhua.png" alt="">
                        <p>成交转化</p>
                    </div>
                    <div class="oo-right">
                        <div class="item">
                            <p class="text">成交金额指数</p>
                            <p class="num" v-if="goodDetail">{{goodDetail.all_transaction_amount}}</p>
                        </div>
                        <div class="item">
                            <p class="text">成交量</p>
                            <p class="num" v-if="goodDetail">{{goodDetail.all_transaction_volume}}</p>
                        </div>
                        <div class="item">
                            <p class="text">成交转化率</p>
                            <p class="num" v-if="goodDetail">{{goodDetail.all_transaction_conversion}}%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-title">趋势图</div>
        <div class="trend-wrapper">
            <div class="trend-category">
                <div class="cate-left">
                    <p>引流漏斗</p>
                    <p>成交转化</p>
                </div>
                <div class="cate-right">
                    <el-checkbox-group v-model="checkList" :max="4">
                        <el-checkbox :label="checkItem" v-for="checkItem in categoryList" :key="checkItem"></el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div class="choose-number">
                已选择:
                <span class="number">
                    <span>{{checkList.length}}</span> / 4
                </span>
            </div>
            <div class="trend-content" id="trendECharts"></div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tabName: this.$route.query.tabName || '',
                goodsId: Number(this.$route.query.id) || '',
                period: Number(this.$route.query.period) || '',
                pageType: this.$route.query.type || '',
                goodDetail: null,
                trendECharts: null,
                checkList: ['浏览量', '点击指数', '成交转化率', '成交单量指数'],
                // categoryList: ['浏览量', '点击指数', '成交单量指数', '成交件数指数', '成交金额指数', '成交单价指数', '成交转化率'],
                categoryList: ['浏览量', '点击指数', '成交转化率', '成交单量指数', '成交件数指数', '成交金额指数'],
                allSeries: [],
            }
        },
        mounted() {
            this.getEChartsData()
            this.trendECharts = this.$echarts.init(document.getElementById('trendECharts'));
            // this.getTrendECharts()

            let resizeTimer = null;
            window.onresize = () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    this.trendECharts.resize()
                }, 100)
            }
        },
        computed: {
            series() {
                return this.allSeries.filter(item => {
                    return this.checkList.includes(item.name)
                })
            }
        },
        watch: {
            series(val) {
                // console.log('seriesVal', val)
                this.getTrendECharts()
            }
        },
        methods: {
            getEChartsData() {
                this.$http.axiosGetBy(this.$api.storeSelectionGoodsDetail, {id: this.goodsId, cycle: this.period}, (res) => {
                    if (res.code === 200) {
                        this.goodDetail = res.data
                        this.allSeries = [
                            {
                                name: '浏览量',
                                type: 'line',
                                data: this.goodDetail.view_num,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#FFAC76",
                                    }
                                },
                            },
                            {
                                name: '点击指数',
                                type: 'line',
                                data: this.goodDetail.click_num,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#ffea00",
                                    }
                                },
                            },
                            {
                                name: '成交单量指数',
                                type: 'line',
                                data: this.goodDetail.transaction_volume,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#26C6DA",
                                    }
                                },
                            },
                            {
                                name: '成交件数指数',
                                type: 'line',
                                data: this.goodDetail.transaction_volume,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#0084FF",
                                    }
                                },
                            },
                            {
                                name: '成交金额指数',
                                type: 'line',
                                data: this.goodDetail.conversion_amount,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#E43C3E",
                                    }
                                },
                            },
                            // {
                            //     name: '成交单价指数',
                            //     type: 'line',
                            //     data: unitIndex,
                            //     smooth: true,
                            //     itemStyle: {
                            //         normal: {
                            //             color: "#00d619",
                            //         }
                            //     },
                            // },
                            {
                                name: '成交转化率',
                                type: 'line',
                                data: this.goodDetail.transaction_conversion,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#4600d6",
                                    }
                                },
                            },
                        ]
                        this.getTrendECharts()
                    } else {
                        this.$message.warning(res.smg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 趋势图
            getTrendECharts() {
                this.trendECharts.setOption({
                    xAxis: {
                        type: 'category',
                        data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
                        boundaryGap: false,
                        axisLabel: {
                            textStyle: {
                                color: '#666'
                            },
                            align: 'center'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            show: false,
                            // textStyle: {
                            //     color: '#666'
                            // }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        backgroundColor: '#fff',
                        textStyle: {
                            color: '#666',
                        },
                        borderColor: '#DCDCDC',
                        borderWidth: 2,
                        formatter: (params) => {
                            let res = params[0].name;
                            for (let i = 0; i < params.length; i++) {
                                if (params[i].seriesName === '成交转化率') {
                                    res += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value + '%';
                                } else {
                                    res += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value;
                                }
                            }
                            return res
                        }
                    },
                    legend: {
                        icon: 'circle',
                        // data: this.checkList,
                        bottom: 0,
                        selectedMode: false
                    },
                    grid: {
                        top: 10,
                        left: 10,
                        right: 20,
                        bottom: 80,
                    },
                    series: this.series
                }, true);
            },
            // 查看商品详情
        }
    }
</script>

<style scoped lang="scss">
    /deep/ p {
        margin: 0;
    }
    .page-title {
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333;
        margin: 20px 0;
        line-height: 1;
    }
    .goods-detail {
        background: #fff;
        padding: 20px;
        display: flex;
        align-items: center;
        .cover {
            width: 60px;
            height: 60px;
            border: 1px solid #eee;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            background: #fafafa;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .goods-title {
            width: 1%;
            flex: 1;
            margin-left: 20px;
        }
    }
    .goods-overview {
        margin-top: 20px;
        background: #fff;
        padding: 20px;
        .title {
            font-size: 16px;
            line-height: 1;
        }
        .overview-content {
            margin-top: 20px;
            border: 1px solid #E5E5E5;
            display: flex;
            .oo-item {
                width: 50%;
                display: flex;
                align-items: center;
                .oo-title {
                    width: 112px;
                    height: 112px;
                    background: #F8F8F8;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 42px;
                        margin-bottom: 2px;
                    }
                }
                .oo-right {
                    width: 1%;
                    flex: 1;
                    display: flex;
                    .item {
                        width: 33.333333%;
                        padding-left: 20px;
                        p {
                            line-height: 1;
                            white-space: nowrap;
                        }
                        .text {
                            font-size: 16px;
                        }
                        .num {
                            font-size: 18px;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
    .trend-wrapper {
        background: #fff;
        padding: 20px;
        .trend-category {
            display: flex;
            border: 1px solid #E5E5E5;
            .cate-left {
                width: 120px;
                background: #F8F8F8;
                p {
                    line-height: 50px;
                    text-align: center;
                    font-size: 16px;
                    border-bottom: 1px solid #E5E5E5;
                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
            .cate-right {
                width: 1%;
                flex: 1;
                .el-checkbox-group {
                    display: flex;
                    flex-wrap: wrap;
                    .el-checkbox {
                        margin-right: 0;
                        line-height: 50px;
                        padding-left: 48px;
                        width: 15%;
                        box-sizing: border-box;
                        &:nth-of-type(2) {
                            width: 85%;
                        }
                        &:nth-of-type(1), &:nth-of-type(2) {
                            border-bottom: 1px solid #E5E5E5;
                        }
                    }
                }
            }
        }
        .choose-number {
            margin-top: 30px;
            font-size: 18px;
            .number {
                margin-left: 20px;
                span {
                    color: #0084FF;
                }
            }
        }
        .trend-content {
            margin-top: 30px;
            height: 300px;
        }
    }
    .goods-attr {
        margin-top: 20px;
        background: #fff;
        .attr-title {
            line-height: 55px;
            padding: 0 20px;
            font-size: 16px;
            border-bottom: 1px solid #E5E5E5;
        }
        .attr-list {
            padding: 20px;
            .item {
                margin-top: 10px;
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
</style>